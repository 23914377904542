export const allowlistAddresses = [
  ['0x1fa6096f902220528b42963a84d171e4de67ac85', 10],
  ['0x2d917b19ccf3d97cd25aa336e579521e2e11bb49', 10],
  ['0xdecf4b112d4120b6998e5020a6b4819e490f7db6', 5],
  ['0xdecf4b112d4120b6998e5020a6b4819e490f7db6', 5],
  ['0xdecf4b112d4120b6998e5020a6b4819e490f7db6', 5],
  ['0xdecf4b112d4120b6998e5020a6b4819e490f7db6', 5],
  ['0xdecf4b112d4120b6998e5020a6b4819e490f7db6', 5],
  ['0xdecf4b112d4120b6998e5020a6b4819e490f7db6', 5],
];
